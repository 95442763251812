import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

import '../styles/pages/product.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Components
import Layout from '../components/layout';
import Header from '../components/header';

const Category = (props) => {
  const {
    data: {
      allMarkdownRemark: { edges },
    },
    pathContext: { category },
  } = props;

  return (
    <Layout>
      <Container>
        <Header title={`Catalog > ${category}`} />
        <Row>
          <Col sm={12} md={3} lg={2}>
            <p
              style={{
                fontWeight: 'bold',
                borderBottom: '1px solid rgba(0,0,0,.2)',
                paddingBottom: '2px',
                marginBottom: '5px',
              }}
            >
              Air Conditioning
            </p>
            <ul style={{ listStyle: 'none', padding: '0' }}>
              <Link to="/catalog/compressor">
                <li style={{ color: '#666', letterSpacing: '.5px', marginBottom: '2px' }}>Compressor</li>
              </Link>
              <Link to="/catalog/condenser">
                <li style={{ color: '#666', letterSpacing: '.5px', marginBottom: '2px' }}>Condenser</li>
              </Link>
              <Link to="/catalog/expansion-valve">
                <li style={{ color: '#666', letterSpacing: '.5px', marginBottom: '2px' }}>Expansion valve</li>
              </Link>
              <Link to="/catalog/filter-dryer">
                <li style={{ color: '#666', letterSpacing: '.5px', marginBottom: '2px' }}>Filter dryer</li>
              </Link>
              <Link to="/catalog/blower-motor">
                <li style={{ color: '#666', letterSpacing: '.5px', marginBottom: '2px' }}>Blower motor</li>
              </Link>
              <Link to="/catalog/evaporator">
                <li style={{ color: '#666', letterSpacing: '.5px', marginBottom: '2px' }}>Evaporator</li>
              </Link>
            </ul>
            <p
              style={{
                fontWeight: 'bold',
                borderBottom: '1px solid rgba(0,0,0,.2)',
                paddingBottom: '2px',
                marginBottom: '5px',
              }}
            >
              Engine Cooling
            </p>
            <ul style={{ listStyle: 'none', padding: '0' }}>
              <Link to="/catalog/radiator">
                <li style={{ color: '#666' }}>Radiator</li>
              </Link>
              <Link to="/catalog/cooling-fan">
                <li style={{ color: '#666' }}>Cooling fan</li>
              </Link>
            </ul>
          </Col>
          <Col sm={12} md={9} lg={{ span: 9, offset: 1 }} style={{ paddingRight: '5px' }}>
            <Row xs={2} sm={2} md={2} lg={4} style={{ marginRight: '-5px', marginLeft: '0' }}>
              {edges.map(({ node: { fields: { slug }, frontmatter: { jyeManNo, coverImgPath } } }) => (
                <Col style={{ paddingLeft: '0' }}>
                  <Link to={`/catalog/product/${slug}`}>
                    <div className="ProductBox" key={jyeManNo}>
                      <Img fixed={coverImgPath.childImageSharp.fixed} />
                      <p>{jyeManNo}</p>
                    </div>
                  </Link>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export const productQuery = graphql`
  query CategoryQuery($category: String!) {
    allMarkdownRemark(filter: { frontmatter: { category: { eq: $category } } }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            jyeManNo
            category
            tags
            coverImgPath {
              childImageSharp {
                fixed(width: 180, height: 180) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Category;
